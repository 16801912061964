import React, { Fragment } from 'react';

import classes from './Main.module.css';
import MainSearchForm from './MainSearchForm';
import MainCardItem from './MainCardItem';

const cardItems = [
  {
    item: 'item 1'
  },
  {
    item: 'item 2'
  },
  {
    item: 'item 3'
  }
];

const cardHeaders = [
  {
    header: 'Most Viewed [24 hrs]'
  },
  {
    header: 'Largest Volume [24 hrs]'
  },
  {
    header: 'sek9 Top Picks'
  },
  {
    header: 'Latest Sales'
  }
];

const Main = () => {
  return (
    <Fragment>
      <div className={classes['form-content']}>
        <MainSearchForm />
      </div>
      <div className={classes['card-content']}>
        {cardHeaders.map((header, index) => {
          return (
            <MainCardItem
              key={index}
              header={header.header}
              items={cardItems}
            />
          );
        })}
      </div>
    </Fragment>
  );
};
export default Main;
