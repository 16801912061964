import { Link, useLocation } from 'react-router-dom';

import classes from './MainMenu.module.css';

const MainMenu = () => {
  // to get active page
  const location = useLocation();
  const { pathname } = location;
  return (
    <nav>
      <ul>
        <li
          className={
            pathname === '/categories' ? classes.active : classes.inactive
          }>
          <Link to="/categories">Categories</Link>
        </li>
        <li
          className={
            pathname === '/favourites' ? classes.active : classes.inactive
          }>
          <Link to="/favourites">Favourites</Link>
        </li>
        <li
          className={pathname === '/exact' ? classes.active : classes.inactive}>
          <Link to="/exactsearch">Exact Search</Link>
        </li>
        <li
          className={
            pathname === '/contact' ? classes.active : classes.inactive
          }>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default MainMenu;
