import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import classes from './MainSearchResult.module.css';
import DomainCard from '../UI/DomainCard';
import MainSearchForm from './MainSearchForm';
import Button from '../UI/Button';

const searchDomainResults = [
  {
    pagesInfo: {
      totalPage: 5
    },
    domain: [
      {
        item: 'Domain 1'
      },
      {
        item: 'Domain 2'
      },
      {
        item: 'Domain 3'
      },
      {
        item: 'Domain 4'
      },
      {
        item: 'Domain 5'
      },
      {
        item: 'Domain 6'
      },
      {
        item: 'Domain 7'
      },
      {
        item: 'Domain 8'
      },
      {
        item: 'Domain 9'
      },
      {
        item: 'Domain 10'
      },
      {
        item: 'Domain 11'
      },
      {
        item: 'Domain 12'
      },
      {
        item: 'Domain 13'
      },
      {
        item: 'Domain 14'
      },
      {
        item: 'Domain 15'
      }
    ]
  }
];

const MainSearchResult = () => {
  const [validSearchReturn, setValidSearchReturn] = useState('false');
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchid = queryParams.get('searchid');
  const page = Number(queryParams.get('page'));

  useEffect(() => {
    // if user play around the url to search,
    // send the users to main page
    if (page === 0 || searchid === null) {
      navigate({
        pathname: '/'
      });
    }
    if (searchDomainResults[0].domain.length > 0) {
      setValidSearchReturn(true);
    }
  }, [navigate, page, searchid, setValidSearchReturn]);

  const previousPageHandler = () => {
    const prePage = page > 1 ? page - 1 : page;
    navigate({
      pathname: location.pathname,
      search: '?searchid=' + searchid + '&page=' + prePage
    });
  };

  const nextPageHandler = () => {
    const nextPage =
      page < searchDomainResults[0].pagesInfo.totalPage ? page + 1 : page;
    navigate({
      pathname: location.pathname,
      search: '?searchid=' + searchid + '&page=' + nextPage
    });
  };

  return (
    // Check domain result is valid or not before showing it
    <Fragment>
      {validSearchReturn && (
        <div>
          <div className={classes['form-content']}>
            <MainSearchForm />
          </div>
          <div className={classes['button-content']}>
            <div>
              <Button className={classes.button} onClick={previousPageHandler}>
                {'< prev'}
              </Button>
            </div>
            <div>
              <p>{page + ' / ' + searchDomainResults[0].pagesInfo.totalPage}</p>
            </div>
            <div>
              <Button className={classes.button} onClick={nextPageHandler}>
                {'next >'}
              </Button>
            </div>
          </div>
          <div className={classes['search-content']}>
            {searchDomainResults[0].domain.map((result, index) => {
              return (
                <DomainCard key={index} className={classes.card}>
                  {result.item}
                </DomainCard>
              );
            })}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default MainSearchResult;
