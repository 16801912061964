import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../UI/Button';
import HamburgerMenuButton from '../UI/HamburgerMenuButton';

import classes from './MainMobileMenu.module.css';

const MainMobileMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  const onClickOpenShowMenuHandler = () => {
    setShowMenu(true);
  };

  const onClickCloseShowMenuHandler = () => {
    setShowMenu(false);
  };

  return (
    <div>
      <HamburgerMenuButton onClick={onClickOpenShowMenuHandler} />
      {showMenu && (
        <div className={classes['mobile-menu']}>
          <p>Menu</p>
          <Link className={classes['mobile-link']} to="/categories">
            Categories
          </Link>
          <Link className={classes['mobile-link']} to="/favourites">
            Favourites
          </Link>
          <Link className={classes['mobile-link']} to="/exactsearch">
            Exact Search
          </Link>
          <Link className={classes['mobile-link']} to="/contact">
            Contact
          </Link>
          <Button onClick={onClickCloseShowMenuHandler}>x</Button>
        </div>
      )}
    </div>
  );
};

export default MainMobileMenu;
