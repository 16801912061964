import { useEffect, useState } from 'react';
import MainMobileMenu from './MainMobileMenu';
import MainMenu from './MainMenu';

import logo from '../../ske9_logo.png';
import Button from '../UI/Button';
import classes from './MainNavigation.module.css';

const MainNavigation = () => {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setMobileViewFunction = () => {
      if (window.innerWidth <= 1024) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    };
    setMobileViewFunction();

    const handleResize = () => {
      setMobileViewFunction();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header className={classes.header}>
      <div className={classes.logos}>
        <img src={logo} className={classes['client-logo']} alt="logo" />
      </div>
      {!mobileView ? <MainMenu /> : <MainMobileMenu />}
      <div className={classes['wallet-button']}>
        <Button>Connect</Button>
      </div>
    </header>
  );
};

export default MainNavigation;
