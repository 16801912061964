import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

import classes from './HamburgerMenuButton.module.css';

const HamburgerMenu = (props) => {
  return (
    <Button
      onClick={props.onClick}
      className={`${classes['hamburger-menu']} ${props.className}`}>
      <span>
        <div></div>
        <div></div>
        <div></div>
      </span>
    </Button>
  );
};

HamburgerMenu.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default HamburgerMenu;
