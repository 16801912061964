import React from 'react';
import PropTypes from 'prop-types';

import Card from '../UI/Card';
import classes from './MainCardItem.module.css';

const MainCardItem = (props) => {
  return (
    <Card className={classes.card}>
      <h5>{props.header}</h5>
      {props.items.map((item, index) => {
        return <p key={index}>{++index + '. ' + item.item}</p>;
      })}
    </Card>
  );
};

MainCardItem.propTypes = {
  header: PropTypes.string,
  items: PropTypes.array
};

export default MainCardItem;
