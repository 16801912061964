import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../UI/Button';
import classes from './MainSearchForm.module.css';

const MainSearchForm = () => {
  const [search, setSearch] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const categoryInputChangeHandler = (event) => {
    setSearch(event.target.value);
  };

  const clearFormHandler = () => {
    setSearch('');
  };

  const formSubmissionHandler = (event) => {
    event.preventDefault();
    let searchString = JSON.stringify(search).replace(/['"]+/g, '');
    if (searchString.trim().length > 0) {
      navigate({
        pathname: location.search === '' ? 'search/result' : '',
        search: '?searchid=' + searchString + '&page=' + 1
      });
      setSearch('');
    }
  };

  return (
    <div className={classes['form-control']}>
      <form onSubmit={formSubmissionHandler}>
        <input
          type="text"
          id="search"
          value={search}
          placeholder="Search"
          onChange={categoryInputChangeHandler}
        />
        <button
          className={classes['form-control-button']}
          onClick={clearFormHandler}>
          x
        </button>
        <div>
          <Button type="submit">Search</Button>
        </div>
      </form>
    </div>
  );
};

export default MainSearchForm;
