import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Layout from './components/Layout/Layout.js';
import MainSearchPage from './pages/MainSearch.js';
import ContactPage from './pages/Contact.js';
import CategoriesPage from './pages/Categories.js';
import FavouritesPage from './pages/Favourites.js';
import ExactSearchPage from './pages/ExactSearch';
import MainSearchResult from './components/Main/MainSearchResult';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/*" element={<MainSearchPage />} />
        <Route path="search/:searchid" element={<MainSearchResult />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/categories" element={<CategoriesPage />} />
        <Route path="/exactsearch" element={<ExactSearchPage />} />
        <Route path="/favourites" element={<FavouritesPage />} />
      </Routes>
    </Layout>
  );
}

export default App;
