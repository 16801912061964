import React from 'react';
import PropTypes from 'prop-types';

import classes from './DomainCard.module.css';

const DomainCard = (props) => {
  return (
    <div className={`${classes.card} ${props.className}`}>{props.children}</div>
  );
};

DomainCard.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export default DomainCard;
